import React, { useState } from 'react';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Form from '@/components/contact/Form';
import { Section } from '@/components';
import { IPageSection } from '@/types';
import { parseFormJSON } from '@/helpers';

const useStyles = makeStyles((theme) => ({
  formSection: {
    [theme.breakpoints.down(`xs`)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    background: `white`,
  },
  navbar: {
    background: `white`,
  },
  tabLabel: {
    fontFamily: `Avenir Roman`,
    fontSize: theme.spacing(2),
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(1.8),
    },
  },
  tabs: {
    padding: theme.spacing(3, 0),
  },
}));

interface FormsProps {
  sections: Record<string, IPageSection>;
}

const Forms: React.FC<FormsProps> = ({ sections }) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<string>(`ContactAppointment`);
  const handleChange = (e: React.ChangeEvent, newSelection: string) => {
    setSelection(newSelection);
  };

  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box className={classes.navbar}>
        <Tabs
          className={classes.tabs}
          value={selection}
          indicatorColor="primary"
          onChange={handleChange}
          centered
        >
          {Object.keys(sections).map((id) => (
            <Tab
              className={classes.tabLabel}
              key={id}
              value={id}
              label={t(id)}
            />
          ))}
        </Tabs>
      </Box>
      <Section
        className={classes.formSection}
        title={t(sections?.[selection]?.title) ?? ``}
        subtitle={t(sections?.[selection]?.subtitle) ?? ``}
      >
        <Form
          components={parseFormJSON(sections?.[selection].children[0].formData)}
          selection={selection}
        />
      </Section>
    </MuiPickersUtilsProvider>
  );
};

export default Forms;
