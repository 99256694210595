import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Banner, Layout, Header, Section, Helmet } from '@/components';
import { Carousel, Contact, Forms } from '@/components/contact';
import { IContent, IHeader, IPageSection } from '@/types';
import { keyBy } from '@/helpers';
import { IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  contact: {
    background: theme.palette.grey.main,
    paddingLeft: 0,
    paddingRight: 0,
  },
  noPadding: {
    padding: 0,
  },
  carousel: {
    padding: theme.spacing(3),
  },
}));

const ContactUs: React.FC<PageProps> = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sections = data.page.children;
  const header = sections[0] as IHeader;
  const formSectionsObj = keyBy(sections.slice(1, 3) as IPageSection[], `id`);
  const contactsSection = sections[3] as IPageSection;
  const banner = sections[4].children[0] as IContent;
  const carouselImages = sections[5].children[0].children as IGatsbyImageData[];

  return (
    <main>
      <Helmet page="Contact Us" />
      <Layout>
        <Header
          title={t(header.title)}
          subtitle={t(header.subtitle)}
          images={[header.image]}
        />
        <Forms sections={formSectionsObj} />
        <Section className={classes.noPadding}>
          <Banner
            image={banner.image}
            pageLink="/services"
            text={t(banner.title)}
          />
        </Section>
        <Section
          title={t(contactsSection.title)}
          subtitle={t(contactsSection.subtitle)}
          className={classes.contact}
        >
          <Contact />
        </Section>
        <Section className={classes.carousel}>
          <Carousel images={carouselImages} />
        </Section>
      </Layout>
    </main>
  );
};

export default ContactUs;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page(id: { eq: "Contact" }) {
      id
      children {
        id
        ... on Header {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        ... on Section {
          id
          title
          subtitle
          children {
            ... on Content {
              id
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              title
              formData
              children {
                ... on File {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
