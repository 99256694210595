import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: `black`,
    height: theme.spacing(5),
    width: theme.spacing(5),
    [theme.breakpoints.down(`sm`)]: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
  iconButton: {
    position: `absolute`,
    [theme.breakpoints.down(`sm`)]: {
      '&:hover, &.MuiButtonBase-root': { backgroundColor: `white` },
      color: `white`,
      padding: theme.spacing(1),
    },
  },
  image: {
    height: theme.spacing(30),
  },
  leftSpace: {
    left: theme.spacing(-5),
    [theme.breakpoints.down(`sm`)]: {
      left: theme.spacing(4),
    },
  },
  rightSpace: {
    [theme.breakpoints.down(`sm`)]: {
      right: theme.spacing(4),
    },
  },
  root: {
    margin: `0 auto`,
    maxWidth: theme.breakpoints.width(`md`),
    padding: theme.spacing(4, 5),
    position: `relative`,
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

const PrevArrow = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={`${className}`} onClick={onClick}>
      <IconButton className={`${classes.iconButton} ${classes.leftSpace}`}>
        <ChevronLeft className={`${classes.arrow}`} />
      </IconButton>
    </Box>
  );
};

const NextArrow = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={`${className}`} onClick={onClick}>
      <IconButton className={`${classes.iconButton} ${classes.rightSpace}`}>
        <ChevronRight className={`${classes.arrow}`} />
      </IconButton>
    </Box>
  );
};

interface CarouselProps {
  images: IGatsbyImageData[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [displayImageCount, setDisplayImageCount] = useState(3);
  const isXSmall = useMediaQuery(theme.breakpoints.only(`xs`));
  const isSmall = useMediaQuery(theme.breakpoints.only(`sm`));
  useEffect(() => {
    let count: number;
    if (isXSmall) {
      count = 1;
    } else if (isSmall) {
      count = 2;
    } else {
      count = 3;
    }
    setDisplayImageCount(count);
  }, [isXSmall, isSmall]);
  return (
    <Box className={classes.root}>
      <Slider
        adaptiveHeight={false}
        dots
        focusOnSelect
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        slidesToShow={displayImageCount}
      >
        {images.map((image) => (
          <GatsbyImage
            key={JSON.stringify(image)}
            className={classes.image}
            image={getImage(image)}
            alt="description"
          />
        ))}
      </Slider>
    </Box>
  );
};

export default Carousel;
