import React from 'react';
import GoogleMapReact from 'google-map-react';
import { makeStyles, Box } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import { useCompanyInfo } from '@/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(75),
    [theme.breakpoints.down(`sm`)]: {
      height: theme.spacing(65),
    },
    [theme.breakpoints.down(`xs`)]: {
      height: theme.spacing(55),
    },
  },
  marker: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    transform: `translate(-${theme.spacing(4)}px, -${theme.spacing(4)}px)`,
    color: theme.palette.primary.main,
  },
}));

interface MarkerProps {
  lat: number;
  lng: number;
}

const Marker: React.FC<MarkerProps> = () => {
  const classes = useStyles();
  return <Room className={classes.marker} />;
};

const GoogleMap: React.FC = () => {
  const {
    coordinate: { lat, lng },
  } = useCompanyInfo();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_API_KEY }}
        zoom={15}
        defaultCenter={{ lat, lng }}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </Box>
  );
};

export default GoogleMap;
