import React from 'react';
import {
  makeStyles,
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { Phone, Room, Schedule } from '@material-ui/icons';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import GoogleMap from '@/components/contact/GoogleMap';
import { useCompanyInfo } from '@/hooks';
import { IDayHour } from '@/types';

const useStyles = makeStyles((theme) => ({
  googleMap: {
    flexGrow: 1,
    height: `100%`,
  },
  icon: {
    fontSize: theme.spacing(0.5),
    minWidth: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  info: {
    alignItems: `start`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `start`,
    margin: 0,
    padding: theme.spacing(2, 3),
    width: theme.spacing(50),
    [theme.breakpoints.down(`sm`)]: {
      width: `100%`,
    },
  },
  infoHeading: {
    color: theme.palette.grey.contrastText,
    fontSize: theme.spacing(3),
    fontWeight: `bold`,
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2.5),
    },
  },
  infoHeadingContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  infoSubHeading: {
    color: theme.palette.grey.contrastText,
    fontSize: theme.spacing(2.3),
    lineHeight: theme.spacing(0.15),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2),
    },
  },
  infoSubHeadingContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  lineHeightFix: {
    lineHeight: theme.spacing(0.2),
  },
  mapContainer: {
    display: `flex`,
    flexWrap: `wrap`,
    [theme.breakpoints.down(`sm`)]: {
      flexDirection: `column`,
    },
  },
}));

// convert 24 hour format to 12 hour format
const formatTime = (time: string) => {
  const [hours, minutes] = time.split(`:`);
  const amPm = Number(hours) > 12 ? `pm` : `am`;
  return `${Number(hours) % 12}:${minutes} ${amPm}`;
};

const getItemText = (type, item) => {
  switch (type) {
    case `Address`:
      return item.address;

    case `Contact`:
      return `${item.number} (${item.type})`;

    case `Hours`:
      return `${item.day.slice(0, 3)} ${formatTime(item.start)} - ${formatTime(
        item.end,
      )}`;

    default:
      return ``;
  }
};

interface InfoRowProps {
  icon: React.ReactElement;
  items: (Record<string, string> | IDayHour)[];
  type: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ icon, items, type }) => {
  const classes = useStyles();
  return (
    <List>
      <ListItem disableGutters>
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography className={classes.infoHeading} variant="h3">
              {type}
            </Typography>
          }
        />
      </ListItem>
      {items.map((item) => (
        <ListItem disableGutters className={classes.infoSubHeadingContainer}>
          <ListItemText
            primary={
              <Typography className={classes.infoSubHeading} variant="h6">
                {getItemText(type, item)}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const Info = () => {
  const {
    name,
    logo,
    address,
    phoneNumber,
    faxNumber,
    hours,
  } = useCompanyInfo();
  const classes = useStyles();

  return (
    <Box className={classes.info}>
      <GatsbyImage image={getImage(logo)} alt={name} />
      <InfoRow icon={<Room />} type="Address" items={[{ address }]} />
      <InfoRow
        icon={<Phone />}
        type="Contact"
        items={[
          { number: phoneNumber, type: `Tel` },
          { number: faxNumber, type: `Fax` },
        ]}
      />
      <InfoRow icon={<Schedule />} type="Hours" items={hours} />
    </Box>
  );
};

const Contact: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <Paper elevation={0}>
        <Box className={classes.mapContainer}>
          <Box>
            <Info />
          </Box>
          <Box className={classes.googleMap}>
            <GoogleMap />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Contact;
